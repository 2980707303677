//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SplitView from '~/components/SplitView.vue';
import FCImage from '~/components/FCImage.vue';

export default {
  name: 'AboveTheFold',
  components: {
    SplitView,
    FCImage,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getDimensions() {
      return {
        google: {
          mobileHeight: '60px',
          mobileWidth: '155px',
          tabletUpHeight: '70px',
          tabletUpWidth: '180px',
        },
      };
    },
  },
};
